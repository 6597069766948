import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";

import { useFormik } from "formik";
import { NameSurname, Price } from "./contactlistCol";
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import "jspdf-autotable";


import {
  getSiparis as onGetSiparis,
  addSiparis as onAddSiparis,
  updateSiparis as onUpdateSiparis,
  deleteSiparis as onDeleteSiparis,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Siparişler | E-Kitap Admin Paneli";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [getDetail, setDetail] = useState();


  const validation = useFormik({

    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          Order_Status_ID: getCategoryID,
        };
        dispatch(onUpdateSiparis(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Order_Status_ID: getCategoryID,
        };
        // save new user
        dispatch(onAddSiparis(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.orders,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);

  const [isEdit, setIsEdit] = useState(false);


  const [getCategoryID, setCategoryID] = useState(null);

  const [getCategory, setCategory] = useState([]);


  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },


      {
        Header: "Toplam Ürün",
        accessor: "ID",
        filterable: true,
        accessor: (cellProps) => (
          <>
            <div className="d-flex gap-3">
            <p>{cellProps.Complated_Order_Detail.length} Adet</p>
            </div>
            </>
        )
       
      },

      {
        Header: "Toplam Fiyat",
        accessor: "TotalPrice",
        filterable: true,
        Cell: cellProps => {
          return <Price {...cellProps} />;
        },
      },

      {
        Header: "Ödeme Tipi",
        accessor: "Complated_Order_Type",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },


      {
        Header: "Ödeme Durumu",
        accessor: "Order_Status.Order_Status_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      
      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClickDetail(userData);
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="edittooltip1" />
                <UncontrolledTooltip placement="top" target="edittooltip1">
                  Detay
                </UncontrolledTooltip>
              </Link>


              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>

            </div>
          );
        },
      },
    ],
    []
  );

  const _getAuthData = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getOrderStatusLuxun/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }

  useEffect(() => {
    _getAuthData()
    if (users && !users.length) {
      dispatch(onGetSiparis());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleMedia = () => {
    setModalMedia(!modalMedia);
  };


  const handleUserClickDetail = (arg) => {
    const user = arg;

    setDetail(user)

    toggleMedia();
  };




  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);

    setCategoryID(user.Order_Status_ID)

    setIsEdit(true);


    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);


  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteSiparis(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Sipariş Listesi" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddProduct={false}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modalMedia} toggle={toggleMedia}>
                    <ModalHeader toggle={toggleMedia} tag="h4">
                      {!!isEdit ? "Düzenle" : "Sipariş Detayları"}
                    </ModalHeader>
                    <ModalBody>

                      <Row>
                        <Col xs={12}>


                          <div className="mb-3">
                            {getDetail && getDetail.Complated_Order_Detail ? (
                              getDetail.Complated_Order_Detail.map((orderDetail, index) => 
                              {
                                console.log("orderDetail123",orderDetail)
                                return(
                                <div key={orderDetail.ID} className="mb-3 border p-3">

                                  {/* Accessing the Products property */}
                                  {orderDetail.Products && (
                                    <>
                                      <div style={{ width: 100, height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img
                                          src={'https://megasubdomain.stechomeyazilim.info/' + orderDetail.Products.ImageCover}
                                          alt=""
                                          className="avatar-sm"
                                          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                        />
                                      </div>

                                      <p><strong>Ürün İsmi:</strong> {orderDetail.Products.Products_Name}</p>
                                      <p><strong>İndirimli Fiyat:</strong> {orderDetail.Products.DiscountedPrice} TL</p>
                                      <p><strong>Fiyat:</strong> {orderDetail.Products.Price} TL</p>
                                      {/* Add additional properties as needed */}
                                    </>
                                  )}
                                </div>
                              )})
                            ) : (
                              <div>No order details available.</div>
                            )}
                          </div>


                        </Col>
                      </Row>


                    </ModalBody>
                  </Modal>

                  <Modal isOpen={modal} toggle={toggle}>

                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>


                            <div className="mb-3">
                              <Label className="form-label">Sipariş Durumu Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryID}
                                onChange={(text) => _setChoiceCategory(text)}
                                value={
                                  getCategoryID
                                }>
                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Order_Status_Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>






                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
