import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname, Price } from "./contactlistCol";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';

import "jspdf-autotable";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Dropzone from 'react-dropzone-uploader';
import { Base64 } from 'js-base64';


import {
  getProduct as onGetProduct,
  addProduct as onAddProduct,
  updateProduct as onUpdateProduct,
  deleteProduct as onDeleteProduct,
} from "store/contacts/actions";
import { isEmpty } from "lodash";
import {QrScanner} from '@yudiel/react-qr-scanner';


//redux
import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Ürünler | E-Kitap ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();

  const [getHtmlDataLong, setHtmlDataLong] = useState();
  const [getFileSubMedia, setFileSubMedia] = useState([]);
  const [getFileSubMediaAdd, setFileSubMediaAdd] = useState([]);
  const [getFileName, setFileName] = useState('');
  const [getSubMediaID, setSubMediaID] = useState();

  const [getCheckISBN, setCheckISBN] = useState(false);

  const [getIsActiveRoman, setIsActiveRoman] = useState(false);
  const [getIsActive, setIsActive] = useState(false);


  // validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.Products_Name) || "",
      price: (contactEdit && contactEdit.Price) || null,
      disprice: (contactEdit && contactEdit.DiscountedPrice) || null,
      stock: (contactEdit && contactEdit.Stock) || null,

      isbn: (contactEdit && contactEdit.Products_ISBN) || null,
      pageCount: (contactEdit && contactEdit.Products_PageCount) || null,
      dateCreated: (contactEdit && contactEdit.Products_DateofIssue) || null,
      cover: (contactEdit && contactEdit.Products_Cover) || null,
      author:(contactEdit && contactEdit.Products_Cover) || null,
    },
    validationSchema: Yup.object({
      title: Yup.string(3).required("Başlığı Giriniz"),


    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          Products_Name: values.title,
          Products_Desc: Base64.encode(getHtmlDataLong),
          Price: values.price,
          DiscountedPrice: values.disprice,
          Stock: values.stock,
          ImageCover: getFileName,
          Products_DateofIssue:values.dateCreated,
          Products_Cover: values.cover,
          Products_PageCount: values.pageCount,
          Products_ISBN:values.isbn,
          Products_Category_ID:getCategoryID,
          Products_Author:values.author,
          IsbnCheck: getCheckISBN,
          Products_Language:values.language,
          Image_Isbn: getIsActive

        };
        dispatch(onUpdateProduct(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Products_Name: values["title"],
          Products_Desc: Base64.encode(getHtmlDataLong),
          Price: values["price"],
          DiscountedPrice: values["disprice"],
          Stock: values["stock"],
          ImageCover: getFileName,

          Products_Cover: values["cover"],
          Products_PageCount: values["pageCount"],
          Products_ISBN:values["isbn"],
          Products_Category_ID:getCategoryID,
          Products_Author: values["author"],
          IsbnCheck: getCheckISBN,
          Products_Language:values["language"],
          Image_Isbn: getIsActive,
          Products_DateofIssue:values["dateCreated"],

        };
        dispatch(onAddProduct(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.products,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);

  const [isEdit, setIsEdit] = useState(false);


  const [getCategoryID, setCategoryID] = useState(null);


  const [getCategory, setCategory] = useState([]);


  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
     
      {
        Header: "Foto",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {cellProps.Image_Isbn == true ?
             <div>
             <img
               className="rounded-circle avatar-xs"
               src={cellProps.ImageCover}
               alt=""
             />
           </div>:
            !cellProps.Image_Isbn == false && cellProps.ImageCover ? (
              <div className="avatar-xs">
                {cellProps.Products_Name != null ? <span className="avatar-title rounded-circle">
                  {cellProps.Products_Name.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={'https://megasubdomain.stechomeyazilim.info/' + cellProps.ImageCover}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Adı",
        accessor: "Products_Name",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "FİYAT",
        accessor: "Price",
        filterable: true,
        Cell: cellProps => {
          return <Price {...cellProps} />;
        },
      },


      {
        Header: "İnd. FİYAT",
        accessor: "DiscountedPrice",
        filterable: true,
        Cell: cellProps => {
          return <Price {...cellProps} />;
        },
      },


      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

           
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const _getAuthData = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getProductsCategoryKitap/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }

  useEffect(() => {
    _getAuthData()
    if (users && !users.length) {
      dispatch(onGetProduct());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleMedia = () => {
    setModalMedia(!modalMedia);
  };


  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);

    setCategoryID(user.Products_SubCategory_ID)
   
    setHtmlDataLong( Base64.decode(user.Products_Desc))
    setIsEdit(true);

    setIsActive(user.Image_Isbn)

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteProduct(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  const _deleteMedia = async (itemData) => {

    try {
          
    axios.delete(`https://getjob.stechomeyazilim.info:5101/deleteProductImageLuxun/${itemData.ID}`).then(async(res) => {
      
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getProductsMediaLuxun/select/${getSubMediaID}`)
    .then((res) => {
      setFileSubMedia(res.data)
    })

    
    })
    } catch (error) {
      //yield put(updateUserFail(error))
    }


  /*  const filteredData = getFileSubMediaAdd.filter(item => item.ID !== itemData.ID )
    setFileSubMediaAdd(filteredData);*/

  }

  const _addMedia = async() => {


    getFileSubMediaAdd.map(async(item, index) => {
      const newUser = {
        Products_ID: getSubMediaID,
        //EducationSubItem_Media_Type: item.fileType,
        Url: item.fileName
      }

      axios.post('https://getjob.stechomeyazilim.info:5101/addProductsMediaLuxun/send', newUser)

      await axios.get(`https://getjob.stechomeyazilim.info:5101/getProductsMediaLuxun/select/${getSubMediaID}`)
      .then((res) => {
        setFileSubMedia(res.data)
      })

      dispatch(onGetProduct());

      toggleMedia();
    })
  }

  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }
  
  const handleChangeStatusBanner = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response)
          setFileSubMediaAdd(oldArray => [{ fileName: result.file.filename, fileType: result.file.mimetype }, ...oldArray]);
        }
      }
    }
  }
  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }

  const [getScan, setScan] = useState(false);
  const [getScan1, setScan1] = useState(false);


  const handleSearch = async (code) => {
    try {
      const response = await axios.get(`https://www.googleapis.com/books/v1/volumes?q=isbn:${code}&key=AIzaSyABIfAmVj-jSvbTh73OHlkKgsM1Uz8xRpw`);
   
      if(response.data.totalItems > 0){
        const bookInfo = response.data.items[0].volumeInfo;

        const title = bookInfo.title;
        const publishedDate = bookInfo.publishedDate;
        const language = bookInfo.language;
        const pageCount = bookInfo.pageCount;

        if( bookInfo.imageLinks != null){
        const image = bookInfo.imageLinks.thumbnail;
        setFileName(image)
        }

        if(getIsActiveRoman == false){
          if(bookInfo.authors.length > 0){
            const authors = bookInfo.authors[0];
            validation.setFieldValue('author', authors);
          }
        }

     validation.setFieldValue('title', title);
     validation.setFieldValue('isbn', code);
     validation.setFieldValue('pageCount', pageCount);
     validation.setFieldValue('language', language);
     validation.setFieldValue('dateCreated', publishedDate);
     
 
      setScan(false)
     
      if(title != null){
       setCheckISBN(true)
      }
      }
    
    
    } catch (error) {
     // setError(error.response.data.error.message);
     console.log("lnsdfl",error.response.data.error.message)
    }
  };

  const handleSearch1 = async (code) => {
    try {

      console.log("code123",code)
      const response = await axios.get(`https://www.googleapis.com/books/v1/volumes?q=isbn:${code}&key=AIzaSyABIfAmVj-jSvbTh73OHlkKgsM1Uz8xRpw`);
   

      console.log("lkn1sdklf",response.data)

      if(response.data.totalItems > 0){
        const bookInfo = response.data.items[0].volumeInfo;

        const title = bookInfo.title;
        const publishedDate = bookInfo.publishedDate;
        const language = bookInfo.language;
        const pageCount = bookInfo.pageCount;
        console.log("klsdfnk",bookInfo)
      
      if(getIsActiveRoman == false){
        if(bookInfo.authors.length > 0){
          const authors = bookInfo.authors[0];
          validation.setFieldValue('author', authors);
        }
      }
       
 
     validation.setFieldValue('title', title);
     validation.setFieldValue('isbn', code);
     validation.setFieldValue('pageCount', pageCount);
     validation.setFieldValue('language', language);
     validation.setFieldValue('dateCreated', publishedDate);
     
   
 
      setScan(false)
     
      if(title != null){
       setCheckISBN(true)
      }
      }
    
    
    } catch (error) {
     // setError(error.response.data.error.message);
     console.log("lnsdfl",error.response.data.error.message)
    }
  };
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Ürün Listesi" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddProduct={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={30}
                    className="custom-header-css"
                  />

            
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Ürün Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>


                          {getScan == true ?  
                          <Col>
                            <div className="text-end">
                              <button
                                  type="button" // Form gönderme işlemi yapmamasını belirt
                              onClick={()=> setScan(false)}
                                className="btn btn-success save-user"
                              >
                                ISBN OKUTMA KAPAT
                              </button>
                            </div>
                          </Col> :
                          <Col>
                            <div className="text-end">
                              <button
                                  type="button" // Form gönderme işlemi yapmamasını belirt
                               onClick={()=> setScan(true)}
                                className="btn btn-success save-user"
                              >
                                ISBN OKUT
                              </button>
                            </div>
                          </Col>}

                         { getScan == true ?  
                          <QrScanner
          onDecode={(result) => handleSearch(result)}
          onError={(error) => console.log(error?.message)}
      /> : null}

                            <div className="mb-3">
                              <Label className="form-label">Ürün Adı</Label>
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                placeholder="Ürün Adı Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>


                            <div className="mb-3">
                              <Label className="form-label">Kategori Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryID}
                                onChange={(text) => _setChoiceCategory(text)}
                                value={
                                  getCategoryID
                                }>
                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Product_Category_Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Kapak</Label>
                              <Input
                                name="cover"
                                label="cover"
                                type="text"
                                placeholder="Kapak Cinsini Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.cover || ""}
                                invalid={
                                  validation.touched.cover &&
                                    validation.errors.cover
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.cover &&
                                validation.errors.cover ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.cover}
                                </FormFeedback>
                              ) : null}
                            </div>


                            <div className="mb-3">
                              <Label className="form-label">Yılı</Label>
                              <Input
                                name="dateCreated"
                                label="dateCreated"
                                type="text"
                                placeholder="Yılını Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.dateCreated || ""}
                                invalid={
                                  validation.touched.dateCreated &&
                                    validation.errors.dateCreated
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.dateCreated &&
                                validation.errors.dateCreated ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.dateCreated}
                                </FormFeedback>
                              ) : null}
                            </div>


                            <div className="mb-3">
                              <Label className="form-label">Yazar</Label>
                              <Input
                                name="author"
                                label="author"
                                type="text"
                                placeholder="Yazar Adını Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.author || ""}
                                invalid={
                                  validation.touched.author &&
                                    validation.errors.author
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.author &&
                                validation.errors.author ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.author}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Sayfa Sayısı</Label>
                              <Input
                                name="pageCount"
                                label="pageCount"
                                type="number"
                                placeholder="Sayfa Sayısını Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.pageCount || ""}
                                invalid={
                                  validation.touched.pageCount &&
                                    validation.errors.pageCount
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.pageCount &&
                                validation.errors.pageCount ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.pageCount}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">ISBN Kodu</Label>
                              <Input
                                name="isbn"
                                label="isbn"
                                type="text"
                                placeholder="ISBN Kodunu Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.isbn || ""}
                                invalid={
                                  validation.touched.isbn &&
                                    validation.errors.isbn
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.isbn &&
                                validation.errors.isbn ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.isbn}
                                </FormFeedback>
                              ) : null}
                            </div>


                            {getScan1 == true ?  
                         null :
                          <Col>
                            <div className="text-end">
                              <button
                                  type="button" // Form gönderme işlemi yapmamasını belirt
                               onClick={()=>  handleSearch1(validation.values.isbn)}
                                className="btn btn-success save-user"
                              >
                                ISBN ONAYLA
                              </button>
                            </div>
                          </Col>}

                          <label htmlFor="resume">2.Isbn</label>

                          <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActiveRoman}
                              onChange={() =>
                                setIsActiveRoman(!getIsActiveRoman)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>

                            <div className="mb-3">
                              <Label className="form-label">Uzun Açıklama</Label>

                              <Form method="post">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={getHtmlDataLong}
                                  onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setHtmlDataLong(data)

                                  }}
                                />
                              </Form>
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Fiyat</Label>
                              <Input
                                name="price"
                                label="price"
                                type="text"
                                placeholder="Fiyat Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.price || ""}
                                invalid={
                                  validation.touched.price &&
                                    validation.errors.price
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.price &&
                                validation.errors.price ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.price}
                                </FormFeedback>
                              ) : null}
                            </div>


                            <div className="mb-3">
                              <Label className="form-label">İndirimli Fiyat</Label>
                              <Input
                                name="disprice"
                                label="disprice"
                                type="number"
                                placeholder="İndirimli Fiyat Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.disprice || ""}
                                invalid={
                                  validation.touched.disprice &&
                                    validation.errors.disprice
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.disprice &&
                                validation.errors.disprice ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.disprice}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Stok Giriniz</Label>
                              <Input
                                name="stock"
                                label="stock"
                                type="number"
                                placeholder="Stok Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.stock || ""}
                                invalid={
                                  validation.touched.stock &&
                                    validation.errors.stock
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.stock &&
                                validation.errors.stock ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.stock}
                                </FormFeedback>
                              ) : null}
                            </div>



                            <div className="mb-3">
                              <Col xs={12} className="mb-3">
                                <label htmlFor="resume">Görsel</label>
                                <Dropzone
                                  getUploadParams={getUploadParams}
                                  onChangeStatus={handleChangeStatus}
                                  styles={{
                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                    inputLabelWithFiles: { margin: '20px 3%' }
                                  }}

                                  canRemove={false}
                                  PreviewComponent={Preview}
                                  accept="image/*"
                                />

                              </Col>
                            </div>

                          </Col>
                        </Row>
                        <Row>

                        <Label className="form-label">Görsel Isbn</Label>
                        <div className="square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>

                         
                          <img
               className="rounded-circle avatar-xs"
               src={getFileName}
               alt=""
             />
                          

                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
